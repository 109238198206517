import React from 'react'

import Loader from './Loader'

function Details({ contents, loading }) {
    if (loading) {
        return <Loader />
    }

    return (
        <div className="container">
            <h2>{contents.fullName}</h2>
            <h4>{contents.name}</h4>
            <p>{contents.description}</p>
            <a href={`${contents.image}`}>{contents.image}</a>
            <br />
            <img src={`${contents.image}`} className="img-fluid" alt={`${contents.image}`}></img>
            <br />
            <small>{contents.created}</small>
        </div>
    )
}

export default Details
