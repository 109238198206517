import React from 'react'

function Loader() {
    return (
        <div className="text-center">
            <div className="spinner-grow text-primary mx-2" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-primary mx-2" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-primary mx-2" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-primary mx-2" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-primary mx-2" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            <br />
            <p>Please wait ...</p>
        </div>

    )
}

export default Loader
