import React, { useState } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import axios from 'axios'
import { Authenticated } from '../Auth/Authentication'

import Loader from './Loader'


function SubEdit({ props, contents, loading }) {
    const { itemID } = useParams()
    const [courseCode, setCourseCode] = useState("")
    const [courseTitle, setCourseTitle] = useState("")
    const [image, setImage] = useState("")
    const [division, setDivision] = useState("")

    const [serverResponse, setServerResponse] = useState(null)

    const handleSubmit = (e) => {
        e.preventDefault()
        let url = `subjects/${itemID}/`

        if (Authenticated()) {
            axios
                .put(url,
                    {
                        courseCode: courseCode,
                        courseTitle: courseTitle,
                        division: division,
                        image: image
                    },
                    {
                        headers: {
                            "Authorization": "Bearer " + localStorage.access
                        }
                    })
                .then(response => {
                    console.log(response.data)
                    setServerResponse("Successfully added")
                })
                .catch(err => {
                    console.log(err)
                    setServerResponse("Failed adding")
                })
        } else {
            setServerResponse("Not authenticated")
        }
    }

    if (loading) {
        return <Loader />
    } else {

        return (
            <div className="container">
                {
                    serverResponse ?
                        (<div className="alert alert-primary" role="alert">
                            {serverResponse}
                        </div>)
                        :
                        null
                }
                <div className="container-lg text-left" style={styles.form}>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label forname="courseCode">Course Code</label>
                            <input id="courseCode" type="text" className="form-control" defaultValue={contents.courseCode} required
                                onChange={(e) => { setCourseCode(e.target.value); setServerResponse(null); }}
                            />
                        </div>
                        <div className="form-group">
                            <label forname="courseTitle">Course Title</label>
                            <input id="courseTitle" type="text" className="form-control" defaultValue={contents.courseTitle} placeholder="Enter Password" required
                                onChange={(e) => { setCourseTitle(e.target.value); setServerResponse(null); }}
                            />
                        </div>
                        <div className="form-group">
                            <label forname="division">Division</label>
                            <input id="division" type="text" className="form-control" defaultValue={contents.division} required
                                onChange={(e) => { setDivision(e.target.value); setServerResponse(null); }}
                            />
                        </div>
                        <div className="form-group">
                            <label forname="image">image</label>
                            <input id="image" type="text" className="form-control" defaultValue={contents.image} required
                                onChange={(e) => { setImage(e.target.value); setServerResponse(null); }}
                            />
                        </div>
                        <small><strong>Created:</strong> {contents.created}</small>
                        <div className="text-center">
                            <button type="submit" className="btn btn-success"> Submit </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const styles = {
    container: {
        backgroundColor: "rgb(255,255,255,0.2)"
    }
}

export default withRouter(SubEdit)