import React from 'react';
import "./Assets/Css/bootstrap.min.css";
import './Assets/Css/Prism.css'

import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';

import Header from './layout/Header'

import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL


function App() {
  return (
    <div>
      <Router>
        <Header />
        <Routes />
      </Router>
    </div>
  );
}

export default App;
