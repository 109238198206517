import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { Authenticated, Logout } from '../Auth/Authentication'


function Header(props) {
    let username = null

    if (Authenticated()) {
        let decoded = jwt_decode(localStorage.access)
        username = decoded.username
    }
    const handeLogout = (e) => {
        username = null
        Logout()
        props.history.push('/login')
    }

    return (
        <nav className="navbar navbar-dark navbar-expand-lg bg-dark">
            <div className="container">
                <NavLink className="navbar-brand" to="/" >
                    <img src={require('../Assets/Images/logo192.png')} width="30" height="30" className="d-inline-block align-top" alt="Logo" />
                    {' '}Mapua Portfolio Admin
                </NavLink>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/divisions">Divisions</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/subjects">Subjects</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/files">Files</NavLink>
                        </li>
                    </ul>
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link" href="https://mapuaportfolio.wtf/">WEBSITE</a>
                        </li>
                        {
                            username ? (
                                <><li className="nav-item">
                                    <NavLink className="nav-link" to="/" disabled>{username}</NavLink>
                                </li>
                                    <button onClick={handeLogout} className="btn btn-danger">
                                        Logout
                                    </button>

                                </>
                            ) :
                                null
                        }
                    </ul>

                </div>
            </div>

        </nav>
    )
}

export default withRouter(Header)
