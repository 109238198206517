import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Authenticated } from '../Auth/Authentication';
import Parse from 'html-react-parser';

import Prism from 'prismjs'
import Loader from './Loader'

function FileAdd({ div, sub, loading }) {
    const [title, setTitle] = useState("")
    const [body, setBody] = useState("")
    const [content, setContent] = useState("")
    const [term, setTerm] = useState("")
    const [subject, setSubject] = useState("")
    const [division, setDivision] = useState("")

    const [disabl, setDisabl] = useState(true)

    const [prev, setPrev] = useState(null)
    const [serverResponse, setServerResponse] = useState(null)

    useEffect(() => {
        setTimeout(() => Prism.highlightAll(), 0)
    }, [prev])

    let subOptions
    let divOptions

    if (loading) {
        return <Loader />
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (division && subject) {
            if (Authenticated()) {
                axios
                    .post("files/", {
                        title: title,
                        body: body,
                        division: division,
                        subject: subject,
                        content: content,
                        term: term
                    },
                        {
                            headers: {
                                "Authorization": "Bearer " + localStorage.access
                            }
                        })
                    .then(response => {
                        console.log(response.data)
                        setServerResponse("Successfully added")
                    })
                    .catch(err => {
                        console.log(err)
                        setServerResponse("Failed adding")
                    })
            } else {
                setServerResponse("Not authenticated")
            }
        } else {
            setServerResponse("Choose course Code")
        }

    }
    if (div) {
        divOptions = div.map((div) => (
            <option key={div.name}>{div.name}</option>
        ))
    }

    if (division) {
        subOptions = sub.filter((sub) => (
            sub.division === division
        )).map((sub) => (
            <option key={sub.courseCode}>{sub.courseCode}</option>
        ))
    }

    if (loading) {
        return <Loader />
    } else {
        return (
            <div className="container">

                <div className="container-lg text-left">
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label forname="courseCode">Course Code</label>
                                <select id="courseCode" className="form-control" defaultValue="Choose ..." required
                                    onChange={(e) => {
                                        setDivision(e.target.value);
                                        setDisabl(false)
                                        setServerResponse(null);
                                    }}
                                >
                                    <option disabled>Choose ...</option>
                                    {divOptions}
                                </select>
                            </div>
                            <div className="form-group  col-md-6">
                                <label forname="subject">Subject</label>
                                <select id="subject" className="form-control" defaultValue="Choose ..." required
                                    disabled={disabl}
                                    onChange={(e) => { setSubject(e.target.value); setServerResponse(null); setPrev(false); }}
                                >
                                    <option disabled>Choose ...</option>
                                    {subOptions}
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label forname="term">Term</label>
                                <input id="term" type="text" className="form-control" placeholder="1Q1819" required
                                    onChange={(e) => { setServerResponse(null); setTerm(e.target.value); setPrev(false); }}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label forname="title">Title</label>
                                <input id="title" type="text" className="form-control" placeholder="Asssignment 1" required
                                    onChange={(e) => { setServerResponse(null); setTitle(e.target.value); setPrev(false); }}
                                />
                            </div></div>

                        <div className="form-group">
                            <label forname="body">Body</label>
                            <input id="body" type="text" className="form-control" placeholder="Pseudocode of ..." required
                                onChange={(e) => { setServerResponse(null); setBody(e.target.value); setPrev(false); }}
                            />
                        </div>
                        <div className="form-group">
                            <label forname="content">Content</label>
                            <textarea id="content" type="text" className="form-control" placeholder="" required
                                onChange={(e) => {
                                    setServerResponse(null); setContent(e.target.value); setPrev(false);
                                }}
                                rows={25}></textarea>
                            <button className="btn btn-info" onClick={(e) => { e.preventDefault(); setPrev(true) }}>Preview</button>

                        </div>
                        {prev ? Parse(content) : null}
                        <div className="text-center">
                            <button type="submit" className="btn btn-success">Add</button>
                        </div>
                        {
                            serverResponse ?
                                (<div className="alert alert-primary" role="alert">
                                    {serverResponse}
                                </div>)
                                :
                                null
                        }
                    </form>
                </div>
            </div>
        )
    }
}



export default FileAdd