import React from 'react'

function Pagination({ contentsPerPage, totalContent, paginate, currentPage }) {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalContent / contentsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav className="pagination justify-content-center">
            {pageNumbers.map(number => (
                <li key={number} className={currentPage === number ? 'page-item active' : 'page-item'}>
                    <button href={`#`} className="page-link"
                        onClick={() => paginate(number)}
                    >
                        {number}
                    </button>
                </li>
            )
            )}
        </nav>
    )
}

export default Pagination
