import React, { useState, useEffect } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import Parse from 'html-react-parser'
import Prism from 'prismjs'

import axios from 'axios'

import { Authenticated } from '../Auth/Authentication'

import Loader from './Loader'

function FileEdit({ props, contents, loading }) {
    const { itemID } = useParams()

    const [title, setTitle] = useState("")
    const [body, setBody] = useState("")
    const [content, setContent] = useState("")
    const [term, setTerm] = useState("")
    const [subject, setSubject] = useState("")
    const [division, setDivision] = useState("")

    const [prev, setPrev] = useState(null)
    const [serverResponse, setServerResponse] = useState(null)


    useEffect(() => {
        setTimeout(() => Prism.highlightAll(), 0)
    }, [prev])

    useEffect(() => {
        setTitle(contents.title)
        setBody(contents.body)
        setContent(contents.content)
        setTerm(contents.term)
        setSubject(contents.subject)
        setDivision(contents.division)
    }, [contents])

    const handleTab = (e) => {
        // console.log(content)
        if (e.key === "Tab") {
            e.preventDefault()
            console.log(e.key)
            const { selectionStart, selectionEnd } = e.target
            console.log(selectionStart)
            setContent(content.substring(0, selectionStart) + "\t" + content.substring(selectionEnd))

        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let url = `files/${itemID}/`

        if (Authenticated()) {
            axios
                .put(url,
                    {
                        title: title,
                        body: body,
                        content: content,
                        term: term,
                        subject: subject,
                        division: division
                    },
                    {
                        headers: {
                            "Authorization": "Bearer " + localStorage.access
                        }
                    })
                .then(response => {
                    console.log(response.data)
                    setServerResponse("Successfully Updated")
                })
                .catch(err => {
                    console.log(err)
                    setServerResponse("Failed adding")
                })
        } else {
            setServerResponse("Not authenticated")
        }
    }

    if (loading) {
        return <Loader />
    } else {

        return (
            <div className="container">
                {
                    serverResponse ?
                        (<div className="alert alert-primary" role="alert">
                            {serverResponse}
                        </div>)
                        :
                        null
                }
                <div className="container-lg text-left" style={styles.form}>
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label forname="courseCode">Course Code</label>
                                <input id="courseCode" className="form-control" value={division} required
                                    onChange={(e) => {
                                        setDivision(e.target.value);
                                        setServerResponse(null);
                                        setPrev(false);
                                    }}
                                />
                            </div>
                            <div className="form-group  col-md-6">
                                <label forname="subject">Subject</label>
                                <input id="subject" className="form-control" value={subject} required
                                    onChange={(e) => {
                                        setSubject(e.target.value); setServerResponse(null);
                                        setPrev(false);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label forname="term">Term</label>
                                <input id="term" type="text" className="form-control" placeholder="1Q1819" value={term} required
                                    onChange={(e) => { setServerResponse(null); setTerm(e.target.value); setPrev(false); }}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label forname="title">Title</label>
                                <input id="title" type="text" className="form-control" placeholder="Asssignment 1" value={title} required
                                    onChange={(e) => { setServerResponse(null); setTitle(e.target.value); setPrev(false); }}
                                />
                            </div></div>

                        <div className="form-group">
                            <label forname="body">Body</label>
                            <input id="body" type="text" className="form-control" placeholder="Pseudocode of ..." value={body} required
                                onChange={(e) => { setServerResponse(null); setBody(e.target.value); setPrev(false); }}
                            />
                        </div>
                        <div className="form-group">
                            <label forname="content">Content</label>
                            <textarea id="content" type="text" className="form-control" placeholder="" value={content} required
                                onChange={(e) => {
                                    setServerResponse(null); setContent(e.target.value); setPrev(false);
                                }}
                                onKeyDown={(e) => handleTab(e)}
                                rows={25}
                            ></textarea>
                            <button className="btn btn-info" onClick={(e) => { e.preventDefault(); setPrev(true) }}>Preview</button>

                        </div>
                        {prev ? Parse(content) : null}
                        <div className="text-center">
                            <button type="submit" className="btn btn-success">Add</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const styles = {
    container: {
        backgroundColor: "rgb(255,255,255,0.2)"
    }
}

export default withRouter(FileEdit)