import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import Home from './Views/Home'
import Login from './Views/Login'
import Division from './Views/Division'
import DivisionDetail from './Views/DivisionDetail'
import FileDetail from './Views/FileDetail'
import SubjectDetail from './Views/SubjectDetail'
import Edit from './Views/EditView'
import Add from './Views/Add'

import { Authenticated } from './Auth/Authentication'

import Error404 from './Views/Error404'

const PrivateRoute = ({ component: Component, ...rest }) => {
    if (Authenticated()) {
        return (
            <Route
                {...rest}
                render={props =>

                    <Component {...props} />

                }
            />
        );
    } else {
        return (
            <Route
                {...rest}
                render={props =>

                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: props.location }
                        }}
                    />

                }
            />
        );
    }

};

const Routes = () => {
    return (
        <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/login' component={Login} />
            <PrivateRoute exact path='/:db' component={Division} />
            <PrivateRoute exact path='/:db/add' component={Add} />
            <PrivateRoute exact path='/divisions/:itemID' component={DivisionDetail} />
            <PrivateRoute exact path='/subjects/:itemID' component={SubjectDetail} />
            <PrivateRoute exact path='/files/:itemID' component={FileDetail} />
            <PrivateRoute exact path='/:db/:itemID/edit' component={Edit} />
            <Route path="*" component={Error404} />
        </Switch>
    )
}

export default Routes
