import React from 'react'
import { Link } from 'react-router-dom'
import { Authenticated } from '../Auth/Authentication'

function Home(props) {


    if (Authenticated()) {
        return (
            <div className="container text-center px-2 py-5" style={styles.container}>
                <h1>Home</h1>
                <div className="container-lg text-center" style={styles.form}>
                    <p>You are authorized to access the admin panels.</p>
                    <div className="text-center">
                        <Link to="/divisions" className="btn btn-primary">Divisions</Link>{' '}
                        <Link to="/subjects" className="btn btn-primary">Subjects</Link>{' '}
                        <Link to="/files" className="btn btn-primary">Files</Link>{' '}
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="container text-center px-2 py-5" style={styles.container}>
                <h1>Home</h1>
                <div className="container-lg text-center" style={styles.form}>
                    <p>You must be logged in to access the admin panel</p>
                    <div className="text-center">
                        <Link to="/login" className="btn btn-primary">LOGIN</Link>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    container: {
        backgroundColor: "rgba(255,255,255,0.2)"
    },
    form: {
        maxWidth: "25rem"
    }
}

export default Home
