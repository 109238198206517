import React, { useState } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { Login, Authenticated } from '../Auth/Authentication'

function LoginPage(props) {
    const [username, setUsername] = useState(null)
    const [password, setpassword] = useState(null)
    const [error, setError] = useState(null)

    const handleSubmit = (e) => {
        e.preventDefault()

        Login(
            {
                username: username,
                password: password,
            }
        )
            .then(response => {
                if (!response.error) {
                    props.history.push("/")
                } else {
                    setError("Invalid credentials")
                }
            })
    }

    if (Authenticated()) {
        return (<><Redirect to="/divisions" /></>)
    } else {
        return (
            <div className="container text-center px-2 py-5" style={styles.container}>
                <h1>Login</h1>

                <div className="container-lg text-left" style={styles.form}>
                    {
                        error ?
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                            :
                            null
                    }
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label forname="username">Username</label>
                            <input id="username" type="text" className="form-control" placeholder="Enter Username" required
                                onChange={(e) => { setUsername(e.target.value); }}
                            />
                        </div>
                        <div className="form-group">
                            <label forname="password">Password</label>
                            <input id="password" type="password" className="form-control" placeholder="Enter Password" required
                                onChange={(e) => { setpassword(e.target.value); }}
                            />
                        </div>
                        <div className="text-center">
                            <button type="submit" className="btn btn-success"> Submit </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const styles = {
    container: {
        backgroundColor: "rgba(255,255,255,0.2)"
    },
    form: {
        maxWidth: "25rem"
    }
}

export default withRouter(LoginPage)
