import React, { useState } from 'react';
import axios from 'axios';
import { Authenticated } from '../Auth/Authentication'

import Loader from './Loader'

function DivAdd({ loading }) {
    const [name, setName] = useState("");
    const [fullName, setFullName] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");

    const [serverResponse, setServerResponse] = useState(null)

    const handleSubmit = (e) => {
        e.preventDefault()

        if (Authenticated()) {
            axios
                .post("divisions/", {
                    name: name,
                    fullName: fullName,
                    description: description,
                    image: image
                },
                    {
                        headers: {
                            "Authorization": "Bearer " + localStorage.access
                        }
                    })
                .then(response => {
                    console.log(response.data)
                    setServerResponse("Successfully added")
                })
                .catch(err => {
                    console.log(err)
                    setServerResponse("Failed adding")
                })
        } else {
            setServerResponse("Not authenticated")
        }
    }

    if (loading) {
        return <Loader />
    } else {

        return (
            <div className="container">

                <div className="container-lg text-left">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label forname="name">Name</label>
                            <input id="name" type="text" className="form-control" placeholder="EECE" required value={name}
                                onChange={(e) => { setName(e.target.value); setServerResponse(null) }}
                            />
                        </div>
                        <div className="form-group">
                            <label forname="fullname">Full Name</label>
                            <input id="fullname" type="text" className="form-control" placeholder="Electronics, Electrical, ..." placeholder="Enter Password" required value={fullName}
                                onChange={(e) => { setFullName(e.target.value); setServerResponse(null) }}
                            />
                        </div>
                        <div className="form-group">
                            <label forname="description">Description</label>
                            <input id="description" type="text" className="form-control" placeholder="Enter description" required value={description}
                                onChange={(e) => { setDescription(e.target.value); setServerResponse(null) }}
                            />
                        </div>
                        <div className="form-group">
                            <label forname="image">Image link</label>
                            <input id="image" type="text" className="form-control" placeholder="https://lorem.picsum/" required value={image}
                                onChange={(e) => { setImage(e.target.value); setServerResponse(null) }}
                            />
                        </div>
                        <div className="text-center">
                            <button type="submit" className="btn btn-success">Add</button>
                        </div>
                        {
                            serverResponse ?
                                (<div className="alert alert-primary" role="alert">
                                    {serverResponse}
                                </div>)
                                :
                                null
                        }
                    </form>
                </div>
            </div>
        )
    }
}


export default DivAdd