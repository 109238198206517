import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams, Link, withRouter } from 'react-router-dom'

import DivContent from '../Components/DivContent'
import SubContent from '../Components/SubContent'
import FileContent from '../Components/FileContent'

import Pagination from '../Components/Pagination'
import Error404 from './Error404';

function Division(props) {
    const { db } = useParams()

    const [contents, setContents] = useState([]);
    const [contProps, setContProps] = useState([])
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [contentsPerPage] = useState(10);
    const [filter, setFilter] = useState("")

    let Content = null;
    useEffect(() => {
        const fetchContents = async () => {
            try {
                setLoading(true);
                const res = await axios.get(`/${db}/`);
                setContents(res.data);
                setContProps(res.data);
                setLoading(false);
            } catch (error) {
                setErr(true);
                setLoading(false);
                setContents(null);
                setContProps(null);
            }
        }

        fetchContents();
    }, [db]);


    if (err) {
        return (<Error404 />)
    }

    if (db.toLowerCase() === "divisions") {
        Content = DivContent
    } else if (db.toLowerCase() === "subjects") {
        Content = SubContent
    } else if (db.toLowerCase() === "files") {
        Content = FileContent
    }

    const handleFilter = (e) => {
        e.preventDefault()

        if (!loading && e.target.value.length > 0) {
            setContProps(
                contents.filter(cont => cont.subject.toLowerCase().match(e.target.value.trim().toLowerCase()))
            )
        } else {
            setContProps(contents)
        }
    }

    const indexOfLastPost = currentPage * contentsPerPage;
    const indexOfFirstPost = indexOfLastPost - contentsPerPage;
    const currentContents = contProps.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="container p-2" style={styles.container}>
            <h1 className="text-center">{(db.charAt(0).toUpperCase() + db.slice(1)).substr(0, db.length - 1)} database</h1>
            <div className="text-center pb-3">
                <Link className="btn btn-success mx-1" to={`${db}/add`} >ADD NEW</Link>
            </div>
            {(db.toLowerCase() === "files") ?
                (
                    <form className="form-inline mb-2">
                        <div className="form-group mx-auto">
                            <label for="search">Filter</label>
                            <input type="text" id="search" className="form-control" placeholder="Subject only" onChange={(e) => handleFilter(e)} />
                        </div>
                    </form>
                )
                :
                null}

            {Content ? <Content contents={currentContents} loading={loading} props={props} /> : null}
            <Pagination contentsPerPage={contentsPerPage} totalContent={contProps.length} paginate={paginate} currentPage={currentPage} />
        </div>
    )
}

const styles = {
    container: {
        backgroundColor: "rgba(255,255,255,0.2)"
    }
}

export default withRouter(Division) 
