import React, { useEffect } from 'react'
import Parse from 'html-react-parser'
import Prism from 'prismjs';

import Loader from './Loader'

import 'prismjs/components/prism-verilog'
import 'prismjs/components/prism-nasm'
import 'prismjs/components/prism-asm6502'
import 'prismjs/components/prism-matlab'

function Details({ contents, loading }) {


    let content = contents.content ? Parse(contents.content) : null

    useEffect(() => {
        setTimeout(() => Prism.highlightAll(), 1)
    }, [content])

    if (loading) {
        return <Loader />
    }

    // let asd = "<pre class='line-numbers lang-cpp'><code>#include <iostream>\n\nusing namespace std;\n\nint main() {\n    cout<<\"Hello PrismJS\";\n}</code ></pre >"

    return (
        <div className="container">
            <h2>{contents.title}</h2>
            <h4>{contents.Body}</h4>
            <p>{contents.division} - {contents.subject}</p>
            {content ? content : null}
            <br />
            {/* {Parse(asd)} */}
            <small>{contents.term}</small>
        </div>
    )
}

export default Details
