import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import Error404 from './Error404';

import DivEdit from '../Components/DivEdit'
import SubEdit from '../Components/SubEdit'
import FileEdit from '../Components/FileEdit'


function EditView() {
    const { db, itemID } = useParams()

    const [contents, setContents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(false);

    let Edit = null;

    useEffect(() => {
        const fetchContents = async () => {
            try {
                setLoading(true);
                const res = await axios.get(`/${db}/${itemID}/`);
                setContents(res.data);

                setLoading(false);
            } catch (error) {
                setErr(true);
                setLoading(false);
                setContents(null);
            }
        }

        fetchContents();
    }, []);

    if (err) {
        return (<Error404 />)
    }

    if (db.toLowerCase() === "divisions") {
        Edit = <DivEdit contents={contents} loading={loading} />
    } else if (db.toLowerCase() === "subjects") {
        Edit = <SubEdit contents={contents} loading={loading} />
    } else if (db.toLowerCase() === "files") {
        Edit = <FileEdit contents={contents} loading={loading} />
    }

    return (
        <div className="container p-2" style={styles.container}>
            <h1>Edit Page</h1>
            {Edit}
        </div>
    )
}

const styles = {
    container: {
        backgroundColor: "rgba(255,255,255,0.2)"
    }
}

export default EditView
