import React, { useState } from 'react'
import Loader from './Loader'
import { NavLink, withRouter } from 'react-router-dom';
import { Authenticated } from '../Auth/Authentication'
import axios from 'axios'

function Content({ props, contents, loading }) {
    const [serverResponse, setServerResponse] = useState(null)

    if (loading) {
        return <Loader />
    }

    const handleDelete = (e, name) => {
        e.preventDefault()
        let url = `/subjects/${name}/`

        if (window.confirm('Are you sure you wish to delete this item?')) {
            console.log("here")
            if (Authenticated()) {
                axios.delete(url, {
                    headers: {
                        "Authorization": "Bearer " + localStorage.access
                    }
                }, {})
                    .then(response => {
                        props.history.push("/subjects")
                        setServerResponse("Deleted Successfully")
                    })
                    .catch(error => {
                        setServerResponse("Error deleting")
                    })
            }
        }
    }

    return (
        <>
            {
                serverResponse ?
                    <div className="alert alert-danger" role="alert">
                        {serverResponse}
                    </div>
                    :
                    null
            }
            <table className="table table-striped table-hover">
                <thead>
                    <tr className="table-success">
                        <th scope="col">Course Code</th>
                        <th scope="col">Course Title</th>
                        <th scope="col">Division</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {contents.map(content => (
                        <tr key={content.id} className="table-secondary">
                            <th scope="row">{content.courseCode}</th>
                            <td>{content.courseTitle}</td>
                            <td>{content.division}</td>
                            <td>
                                <div className="btn-group">
                                    <NavLink className="btn btn-info mx-1" to={`subjects/${content.courseCode ? content.courseCode.toLowerCase() : content.courseCode}`} >VIEW</NavLink>
                                    <NavLink className="btn btn-warning mx-1" to={`subjects/${content.courseCode ? content.courseCode.toLowerCase() : content.courseCode}/edit`} >EDIT</NavLink>
                                    <button type="button" className="btn btn-danger mx-1" onClick={e => handleDelete(e, content.courseCode)}>
                                        DELETE
                                    </button>
                                </div>

                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default Content