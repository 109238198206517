import React from 'react'
import { NavLink } from 'react-router-dom';

function Error404() {
    return (
        <div className="container jumbotron jumbotron-fluid px-5 bg-secondary" >
            <h1 className="display-4">ERROR 404</h1>
            <p className="lead">Page not found</p>
            <hr className="my-4" />
            <p>The page you are trying to request is not found.</p>
            <NavLink className="btn btn-primary" to="/" >HOME</NavLink>
        </div>
    )
}

export default Error404
