import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'

import Details from '../Components/FileDetails'
import Error404 from './Error404';


function FileDetail() {
    const { itemID } = useParams()

    const [contents, setContents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(false);

    useEffect(() => {
        const fetchContents = async () => {
            try {
                setLoading(true);
                const res = await axios.get(`/files/${itemID}/`);
                setContents(res.data);
                setLoading(false);
            } catch (error) {
                setErr(true);
                setLoading(false);
                setContents(null);
            }
        }

        fetchContents();
    }, []);

    if (err) {
        return (<Error404 />)
    }


    return (
        <div className="container p-2" style={styles.container}>
            <h1>Details window</h1>
            <Details contents={contents} loading={loading} />
        </div>
    )
}

const styles = {
    container: {
        backgroundColor: "rgba(255,255,255,0.2)"
    }
}

export default FileDetail
