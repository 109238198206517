import React, { useState, useEffect } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import axios from 'axios'
import { Authenticated } from '../Auth/Authentication'

import Loader from './Loader'

function DivEdit({ props, contents, loading }) {
    const { itemID } = useParams()

    const [name, setName] = useState("");
    const [fullName, setFullName] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");

    const [serverResponse, setServerResponse] = useState(null)

    useEffect(() => {
        setName(contents.name)
        setFullName(contents.fullName)
        setDescription(contents.description)
        setImage(contents.image)
    }, [contents])

    const handleSubmit = (e) => {
        e.preventDefault()
        let url = `divisions/${itemID}/`

        if (Authenticated()) {
            axios
                .put(url,
                    {
                        name: name,
                        fullName: fullName,
                        description: description,
                        image: image
                    },
                    {
                        headers: {
                            "Authorization": "Bearer " + localStorage.access
                        }
                    })
                .then(response => {
                    console.log(response.data)
                    setServerResponse("Successfully added")
                })
                .catch(err => {
                    console.log(err)
                    setServerResponse("Failed adding")
                })
        } else {
            setServerResponse("Not authenticated")
        }
    }

    if (loading) {
        return <Loader />
    } else {

        return (
            <div className="container">
                {
                    serverResponse ?
                        (<div className="alert alert-primary" role="alert">
                            {serverResponse}
                        </div>)
                        :
                        null
                }
                <div className="container-lg text-left">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label forname="name">Name</label>
                            <input id="name" type="text" className="form-control" defaultValue={name} required
                                onChange={(e) => { setName(e.target.value); setServerResponse(null); }}
                            />
                        </div>
                        <div className="form-group">
                            <label forname="fullname">Full Name</label>
                            <input id="fullname" type="text" className="form-control" defaultValue={fullName} placeholder="Enter Password" required
                                onChange={(e) => { setFullName(e.target.value); setServerResponse(null); }}
                            />
                        </div>
                        <div className="form-group">
                            <label forname="description">description</label>
                            <input id="description" type="text" className="form-control" defaultValue={description} required
                                onChange={(e) => { setDescription(e.target.value); setServerResponse(null); }}
                            />
                        </div>
                        <div className="form-group">
                            <label forname="image">image</label>
                            <input id="image" type="text" className="form-control" defaultValue={image} required
                                onChange={(e) => { setImage(e.target.value); setServerResponse(null); }}
                            />
                        </div>
                        <small><strong>Created:</strong> {contents.created}</small>
                        <div className="text-center">
                            <button type="submit" className="btn btn-success"> Submit </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const styles = {
    container: {
        backgroundColor: "rgb(255,255,255,0.2)"
    }
}

export default withRouter(DivEdit)