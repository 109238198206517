import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios'


import DivAdd from '../Components/DivAdd'
import SubAdd from '../Components/SubAdd'
import FileAdd from '../Components/FileAdd'

function Add() {
    const { db } = useParams()
    let AddComponent = null

    const [div, setDiv] = useState([]);
    const [sub, setSub] = useState([])
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(false);

    useEffect(() => {
        setLoading(true);
        const fetchContents = async () => {
            try {
                if (db === "subjects") {
                    const res = await axios.get(`/divisions/`);
                    setDiv(res.data);
                }
                if (db === "files") {
                    const res = await axios.get(`/divisions/`);
                    setDiv(res.data);
                    const resp = await axios.get(`/subjects/`);
                    setSub(resp.data)
                }
                setLoading(false);
            } catch (error) {
                setErr(true);
                setLoading(false);
                setDiv([]);
                setSub([])
            }
        }

        fetchContents();
    }, []);

    if (db === "divisions") {
        AddComponent = <DivAdd loading={loading} />
    } else if (db === "subjects") {
        AddComponent = <SubAdd div={div} loading={loading} />
    } if (db === "files") {
        AddComponent = <FileAdd div={div} sub={sub} loading={loading} />
    }

    return (
        <div className="container p-2" style={styles.container}>
            <h1 className="text-center">Add {db}</h1>
            {AddComponent}
        </div>
    )
}

const styles = {
    container: {
        backgroundColor: "rgba(255,255,255,0.2)"
    }
}

export default Add
