import axios from 'axios';
import jwt_decode from 'jwt-decode'

export const Login = user => {
    return axios
        .post("token/", user)
        .then(response => {
            localStorage.setItem('access', response.data.access)
            localStorage.setItem('refresh', response.data.refresh)
            return response.data
        })
        .catch(error => {
            return {
                error: error
            }
        })
}

export const Authenticated = () => {
    //console.log("token", localStorage.usertoken)

    if (localStorage.access && localStorage.refresh) {
        let decoded = jwt_decode(localStorage.access)
        let expDate = decoded.exp * 1000
        if ((new Date(expDate)) < (new Date())) {
            return axios
                .post("token/refresh/", {
                    refresh: localStorage.refresh
                })
                .then(response => {
                    localStorage.setItem('access', response.data.access)
                    return true
                })
                .catch(error => {
                    localStorage.removeItem("access")
                    localStorage.removeItem("refresh")
                    return false
                })
        } else {
            return true
        }
    } else {
        return false
    }
}

export const Logout = () => {
    localStorage.removeItem("access")
    localStorage.removeItem("refresh")
    return null
}