import React, { useState } from 'react';
import axios from 'axios';
import { Authenticated } from '../Auth/Authentication'

import Loader from './Loader'

function SubAdd({ div, loading }) {
    const [courseCode, setCourseCode] = useState("")
    const [courseTitle, setCourseTitle] = useState("")
    const [image, setImage] = useState("")
    const [division, setDivision] = useState("")


    const [serverResponse, setServerResponse] = useState(null)

    if (loading) {
        return <Loader />
    }
    console.log(div)

    let divOptions = div.map((div) => (
        <option key={div.name}>{div.name}</option>
    ))

    const handleSubmit = (e) => {
        e.preventDefault()
        if (division) {
            if (Authenticated()) {
                axios
                    .post("subjects/", {
                        courseCode: courseCode,
                        courseTitle: courseTitle,
                        division: division,
                        image: image
                    },
                        {
                            headers: {
                                "Authorization": "Bearer " + localStorage.access
                            }
                        })
                    .then(response => {
                        console.log(response.data)
                        setServerResponse("Successfully added")
                    })
                    .catch(err => {
                        console.log(err)
                        setServerResponse("Failed adding")
                    })
            } else {
                setServerResponse("Not authenticated")
            }
        } else {
            setServerResponse("Choose course Code")
        }

    }



    if (loading) {
        return <Loader />
    } else {

        return (
            <div className="container">

                <div className="container-lg text-left">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label forname="courseCode">Course Code</label>
                            <select id="courseCode" className="form-control" defaultValue="Choose ..." required
                                onChange={(e) => { setDivision(e.target.value); setServerResponse(null) }}
                            >
                                <option disabled>Choose ...</option>
                                {divOptions}
                            </select>
                        </div>
                        <div className="form-group">
                            <label forname="courseCode">Course Code</label>
                            <input id="courseCode" type="text" className="form-control" placeholder="CPE101" required
                                onChange={(e) => { setCourseCode(e.target.value); setServerResponse(null) }}
                            />
                        </div>
                        <div className="form-group">
                            <label forname="courseTitle">Course Title</label>
                            <input id="courseTitle" type="text" className="form-control" placeholder="Computer Engineering as A Discipline" required onChange={(e) => { setCourseTitle(e.target.value); setServerResponse(null) }}
                            />
                        </div>
                        <div className="form-group">
                            <label forname="image">Image link</label>
                            <input id="image" type="text" className="form-control" placeholder="https://lorem.picsum/" required
                                onChange={(e) => { setImage(e.target.value); setServerResponse(null) }}
                            />
                        </div>
                        <div className="text-center">
                            <button type="submit" className="btn btn-success">Add</button>
                        </div>
                        {
                            serverResponse ?
                                (<div className="alert alert-primary" role="alert">
                                    {serverResponse}
                                </div>)
                                :
                                null
                        }
                    </form>
                </div>
            </div>
        )
    }
}


export default SubAdd